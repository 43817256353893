import React from 'react';
import useWindowDimensions from './useWindowDimensions';
import CompanyTicker from './CompanyTicker';



export default function CompanyTickerBanner() {
  const { height, width } = useWindowDimensions();

  return (
    <div style={styles.bannerContainer}>
      <div style={styles.tickersContainer}>
        {width > 768 ? (
          <>
            <CompanyTicker delay={8000} imageIds={[2, 3, 12]} />
            <CompanyTicker delay={2000} imageIds={[1, 7, 9]} />
            <CompanyTicker delay={6000} imageIds={[5, 6, 10]} />
            <CompanyTicker delay={4000} imageIds={[8, 4, 11]} />
          </>
        ) : (
          <>
            <CompanyTicker delay={4000} duration={4000} imageIds={[6, 5, 3, 2, 9, 12]} />
            <CompanyTicker delay={2000} duration={4000} imageIds={[8, 4, 1, 7, 10, 11]} />
          </>
        )}
      </div>
    </div>
  );
}

const styles = {
  bannerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0px -3px 8px -3px rgba(0, 0, 0, 0.3), 0px 3px 8px -3px rgba(0, 0, 0, 0.3)', // Add shadows
    background: 'linear-gradient(to right, white, #6271f0)', // Gradient background
    zIndex: '2',
    padding: '10px 10px', // Add some padding for a better appearance

  },
  tickersContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '100%', // Ensure it spans the full width
  },
};
