import React from 'react';
import styles from './IntroMessage.module.css';

const IntroMessage = () => (
  <div className={styles.introMessage}>
    <h1>Custom</h1>
    <h1>Software</h1>
    <p>For individuals, startups, and businesses.</p>
  </div>
);

export default IntroMessage;
