import React from "react";
import { FaGithub as GithubIcon } from 'react-icons/fa';
import { RiInstagramFill as InstagramIcon } from 'react-icons/ri';
import { IoMdMail as EmailIcon } from 'react-icons/io';
import { FaTwitter as TwitterIcon } from 'react-icons/fa';
import './SocialContactBanner.css';

export default function SocialContactBanner({ isHamburger }) {
  const handleButtonClicked = (buttonName) => {
    console.log(buttonName);
  };

  return (
    <div className={`social-icon-banner ${isHamburger ? 'hamburger' : ''}`}>
      <div
        className="button-icon-container"
        onClick={() => handleButtonClicked('email')}
      >
        <EmailIcon size={30} className="social-icon" />
      </div>
      <div
        className="button-icon-container"
        onClick={() => handleButtonClicked('github')}
      >
        <GithubIcon size={30} className="social-icon" />
      </div>
      <div
        className="button-icon-container"
        onClick={() => handleButtonClicked('instagram')}
      >
        <InstagramIcon size={30} className="social-icon" />
      </div>
      <div
        className="button-icon-container"
        onClick={() => handleButtonClicked('twitter')}
      >
        <TwitterIcon size={30} className="social-icon" />
      </div>
    </div>
  );
}
