import React from "react";
import { Button } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import './HeaderBarMiscButtons.css';

import { useScroll } from '../ScrollContext';

export default function HeaderBarMiscButtons({ isHamburger }) {
  const { scrollToSection } = useScroll();

  const handleButtonClicked = (buttonName) => {
    console.log(buttonName);
    if (buttonName == 'get-quote'){
      scrollToSection('project-submission');
    }
  };

  return (
    <div className={`header-bar-misc-buttons ${isHamburger ? 'hamburger' : ''}`}>
      <div className="join-our-team-button-container" onClick={() => handleButtonClicked('join-team')}>
        <span className="join-our-team-text">Join our team</span>
      </div>
      <div className="get-quote-button-container">
        <Button color="green" appearance="primary" onClick={() => handleButtonClicked('get-quote')}>
          <div className="button-inside-container">
            <div className="button-text-container">Get quote</div>
          </div>
        </Button>
      </div>
    </div>
  );
}
