import React from 'react';
import PropTypes from 'prop-types';
import { FaPaperPlane as PaperPlaneIcon } from 'react-icons/fa';
import styles from './ProjectForm.module.css';

const ProjectForm = ({ onQuoteSubmit }) => {
    const handleSubmit = () => {
        // Form submission logic here
        console.log("Form submission logic here : ProjectForm.js");
        onQuoteSubmit(); // Show the modal
    };
    
  return (
    <div className={styles.projectForm} >
      <h2>What do you need?</h2>
      <h3>Your ideas, our expertise.</h3>
      <div className={styles.checkboxes}>
        <label className={styles.checkboxLabel}>
          <input type="checkbox" className={styles.checkbox} /> Mobile
        </label>
        <label className={styles.checkboxLabel}>
          <input type="checkbox" className={styles.checkbox} /> Graphics
        </label>
        <label className={styles.checkboxLabel}>
          <input type="checkbox" className={styles.checkbox} /> Fintech
        </label>
        <label className={styles.checkboxLabel}>
          <input type="checkbox" className={styles.checkbox} /> Web
        </label>
        <label className={styles.checkboxLabel}>
          <input type="checkbox" className={styles.checkbox} /> API
        </label>
        <label className={styles.checkboxLabel}>
          <input type="checkbox" className={styles.checkbox} /> AR
        </label>
        <label className={styles.checkboxLabel}>
          <input type="checkbox" className={styles.checkbox} /> Desktop
        </label>
        <label className={styles.checkboxLabel}>
          <input type="checkbox" className={styles.checkbox} /> ML
        </label>
        <label className={styles.checkboxLabel}>
          <input type="checkbox" className={styles.checkbox} /> Cloud
        </label>
      </div>
      <div className={styles.textBoxes}>
        <input type="email" className={styles.emailInput} placeholder="Your email..." />
        <textarea className={styles.ideaInput} placeholder="Your idea..."></textarea>
      </div>
      <button className={styles.sendButton} onClick={() => handleSubmit()}>
        <PaperPlaneIcon /> Get Quote
      </button>
    </div>
  );
};

ProjectForm.propTypes = {
    onQuoteSubmit: PropTypes.func.isRequired,
  };

  export default ProjectForm;