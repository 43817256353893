import React, { useState, useEffect } from 'react';
import "./Header.css";
// import styles from './Header.module.css';
import SocialContactBanner from "./SocialContactBanner";
import HeaderBarMiscButtons from "./HeaderBarMiscButtons";
import useWindowDimensions from './useWindowDimensions';
import { GiCakeSlice as CakeIcon } from 'react-icons/gi';
import { GiHamburgerMenu as HamburgerIcon } from 'react-icons/gi';

import { useScroll } from '../ScrollContext';

const MIN_WIDTH = 768; // Minimum width to trigger the hamburger menu

export default function Header() {
  const { width, height } = useWindowDimensions();
  const [isHamburgerOpen, setIsHamburgerOpen] = useState(false);

  const { scrollToSection } = useScroll();
  
  // Check if the hamburger menu should be active
  const isHamburger = width < 0.85 * height || width < MIN_WIDTH;

  // Close the menu whenever the screen shrinks to a width that requires the hamburger menu
  useEffect(() => {
    if (isHamburger) {
      setIsHamburgerOpen(false);
    }
  }, [isHamburger]);

  const toggleHamburgerMenu = () => {
    setIsHamburgerOpen(!isHamburgerOpen);
  };

  return (
    <header className="header-container">
      <div className="header-box">
        <div className="logo-container" onClick={() => scrollToSection('intro')}>
          <CakeIcon size={50} className={'cakeIcon'}/>
          <span className="logo-text">Simple</span>
          <span className="logo-text"><b>Software</b></span>
        </div>
        {isHamburger ? (
          <div className="hamburger-button-container" onClick={toggleHamburgerMenu}>
            <HamburgerIcon color="#FFFFFF" size={30} className="hamburger-menu-icon" />
          </div>
        ) : (
          <>
            <div className="header-bar-misc-buttons-container">
              <HeaderBarMiscButtons isHamburger={false} />
            </div>
            <div className="social-banner-container">
              <SocialContactBanner isHamburger={false} />
            </div>
          </>
        )}
        {isHamburger && isHamburgerOpen && (
          <div className="hamburger-menu">
            <div className="header-bar-misc-buttons-container">
              <HeaderBarMiscButtons isHamburger={true} />
            </div>
            <div className="social-banner-container">
              <SocialContactBanner isHamburger={true} />
            </div>
          </div>
        )}
      </div>
    </header>
  );
}
