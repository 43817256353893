import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import './CompanyTicker.css';
import companyTickerImagesDict from '../companyTickerImagesDict';
import useWindowDimensions from './useWindowDimensions';

export default function CompanyTicker(props) {
  const delay = props.delay || 0;
  const STANDARD_INTERVAL = props.duration || 8000;
  const [waitInterval, setWaitInterval] = useState(delay);
  const imageIds = props.imageIds;
  const [imageIdCounter, setImageIdCounter] = useState(0);
  const { height, width } = useWindowDimensions();
  const [show, setShow] = useState(true);

  const updateImageIdCounter = () => {
    let nextImageIdCounter = (imageIdCounter + 1) % imageIds.length;
    setImageIdCounter(nextImageIdCounter);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setWaitInterval(STANDARD_INTERVAL);
      setShow(!show);
    }, waitInterval);

    return () => {
      clearTimeout(timeout);
    };
  }, [imageIdCounter]);

  const onEntered = () => {};
  const onExit = () => {
    updateImageIdCounter();
    setShow(!show);
  };

  return (
    <CSSTransition
      in={show}
      timeout={1000}
      classNames="transition"
      appear
      onEntered={onEntered}
      onExit={onExit}
    >
      <img
        src={companyTickerImagesDict[imageIds[imageIdCounter]].src}
        style={width > height ? { width: '15%' } : { width: '30%' }}
        alt={companyTickerImagesDict[imageIds[imageIdCounter]].title}
      />
    </CSSTransition>
  );
}
