import React from 'react';
import PropTypes from 'prop-types';
import styles from './TeamMemberCard.module.css';

const TeamMemberCard = ({ name, role, image, socialLinks }) => (
  <div className={styles.card}>
    <img src={image} alt={`${name}`} className={styles.image} />
    <h3 className={styles.name}>{name}</h3>
    <p className={styles.role}>{role}</p>
    {/* <div className={styles.socialLinks}>
      {socialLinks.map((link, index) => (
        <a key={index} href={link.url} target="_blank" rel="noopener noreferrer">
          <img src={link.icon} alt={link.name} />
        </a>
      ))}
    </div> */}
  </div>
);

TeamMemberCard.propTypes = {
  name: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  socialLinks: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default TeamMemberCard;


