import React from 'react';
import { useScroll } from '../ScrollContext';
import styles from './QuoteEntryPoint.module.css';

const QuoteEntryPoint = () => {
  const { scrollToSection } = useScroll();

  return (
    <div className={styles.quoteEntryPoint}>
      <button className={styles.quoteButton} onClick={() => scrollToSection('project-submission')}>
        Get Quote
      </button>
      <div className={styles.quoteText}>Your ideas, our expertise.</div>
    </div>
  );
};

export default QuoteEntryPoint;
