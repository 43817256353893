import React, { useState } from 'react';
import './App.css';
import Header from './components/Header';
import CompanyTickerBanner from './components/CompanyTickerBanner';
import corporateArtImage from './assets/images/art/iStock-960218488_[Converted]_no_background.svg';
import projectArtImage from './assets/images/art/project-image-rocket-6.svg';
import formSentImage from './assets/images/art/form-sent-image-1.svg';

/*

Push join team and get quote header buttons right

Keep Cards consistent hieght when resizing

*/

/*new*/
import { FaPaperPlane as PaperPlaneIcon } from 'react-icons/fa';

import ProjectForm from './components/ProjectForm';
//import './global.css'; // Import global styles
import layoutStyles from './Layout.module.css';

import Section from './components/Section';

import IntroMessage from './components/IntroMessage';
import QuoteEntryPoint from './components/QuoteEntryPoint';
import FunPicture from './components/FunPicture';

import { ScrollProvider } from './ScrollContext';

import Modal from './components/Modal';

import FormSubmissionContent from './components/FormSubmissionContent'; // Ensure this path is correct

import TeamSection from './components/TeamSection'; // Import the new TeamSection component

function App() {

  const [showQuoteModal, setShowQuoteModal] = useState(false);
  const [showJoinModal, setShowJoinModal] = useState(false);

  const toggleQuoteModal = () => setShowQuoteModal(!showQuoteModal);
  const toggleJoinModal = () => setShowJoinModal(!showJoinModal);


  return (
    <ScrollProvider>
      <div className="App">

        <Header />

        <main className="main-content">

          <Section id="intro">
            <div className="intro-primary">
              <div className="intro-left">
                <IntroMessage />
                <QuoteEntryPoint />
              </div>
              <div className="intro-right">
                <img src={corporateArtImage} alt="Corporate Art" className="corporate-art-image" />
              </div>
            </div>
            <div className="intro-footer">
              <CompanyTickerBanner />
            </div>
          </Section>

          <Section id="project-submission">
            <div className='project-submission-primary'>
              <div className="project-submission-left">
                <img src={projectArtImage} alt="Project Art" className="project-art-image" />
              </div>
              <div className='project-submission-right'> 
                <ProjectForm onQuoteSubmit={toggleQuoteModal} />
              </div>
            </div>
          </Section>

          <Section id="team-section">
            <TeamSection /> {/* New Team Section */}
          </Section>

        </main>
        <Modal show={showQuoteModal} onClose={toggleQuoteModal}>
          <FormSubmissionContent />
        </Modal>

      </div>
    </ScrollProvider>
    
  );
}

export default App;

