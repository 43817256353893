import React from 'react';
import Slider from 'react-slick';
import TeamMemberCard from './TeamMemberCard';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './TeamSection.module.css';
import { GiCakeSlice as CakeIcon } from 'react-icons/gi';

const teamMembers = [
  {
    name: 'Laurence Brown',
    role: 'Founder / CEO',
    image: require('../assets/animation_resources/team_members/brown.jpg'),
    socialLinks: [
      { url: 'https://facebook.com', icon: 'path/to/facebook-icon.png', name: 'Facebook' },
      { url: 'https://instagram.com', icon: 'path/to/instagram-icon.png', name: 'Instagram' },
    ],
  },
  {
    name: 'Umang Prithani',
    role: 'Director of Global Operations',
    image: require('../assets/animation_resources/team_members/prithani.jpg'),
    socialLinks: [
      { url: 'https://twitter.com', icon: 'path/to/twitter-icon.png', name: 'Twitter' },
      { url: 'https://linkedin.com', icon: 'path/to/linkedin-icon.png', name: 'LinkedIn' },
    ],
  },
  {
    name: 'Davis Schwenke',
    role: 'Product Consultant',
    image: require('../assets/animation_resources/team_members/schwenke.png'),
    socialLinks: [
      { url: 'https://youtube.com', icon: 'path/to/youtube-icon.png', name: 'YouTube' },
      { url: 'https://snapchat.com', icon: 'path/to/snapchat-icon.png', name: 'Snapchat' },
    ],
  },
  {
    name: 'Hector Sanchez',
    role: 'Council',
    image: require('../assets/animation_resources/team_members/sanchez.jpeg'),
    socialLinks: [
      { url: 'https://youtube.com', icon: 'path/to/youtube-icon.png', name: 'YouTube' },
      { url: 'https://snapchat.com', icon: 'path/to/snapchat-icon.png', name: 'Snapchat' },
    ],
  },
  {
    name: 'Alfred McGowan',
    role: 'Cloud Systems Manager',
    image: require('../assets/animation_resources/team_members/mcgowan.jpeg'),
    socialLinks: [
      { url: 'https://youtube.com', icon: 'path/to/youtube-icon.png', name: 'YouTube' },
      { url: 'https://snapchat.com', icon: 'path/to/snapchat-icon.png', name: 'Snapchat' },
    ],
  },
  {
    name: 'Madison Jo',
    role: 'Graphic Designer',
    image: require('../assets/animation_resources/team_members/jo.jpg'),
    socialLinks: [
      { url: 'https://youtube.com', icon: 'path/to/youtube-icon.png', name: 'YouTube' },
      { url: 'https://snapchat.com', icon: 'path/to/snapchat-icon.png', name: 'Snapchat' },
    ],
  },
  {
    name: 'Rishabh Jain',
    role: 'Project Manager',
    image: require('../assets/animation_resources/team_members/jain.jpg'),
    socialLinks: [
      { url: 'https://youtube.com', icon: 'path/to/youtube-icon.png', name: 'YouTube' },
      { url: 'https://snapchat.com', icon: 'path/to/snapchat-icon.png', name: 'Snapchat' },
    ],
  },

  // Add more team members here
];

const TeamSection = () => {
    const settings = {
      dots: false,
      infinite: true,
      speed: 5000,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 0,
      cssEase: 'linear',
      pauseOnHover: false,
      arrows:false,
    };
  
    return (
      <section className={styles.teamSection}>
        <h2 className={styles.heading}>
            Your Team
        </h2>
        <p className={styles.description}>
            Building the software that builds your business.
        </p>
        {/* <CakeIcon className={styles.icon} /> */}
        <Slider {...settings} className={styles.slider}>
          {teamMembers.map((member, index) => (
            <TeamMemberCard key={index} {...member} />
          ))}
        </Slider>
      </section>
    );
  };
  
export default TeamSection;

