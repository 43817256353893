import React from 'react';
import { GiCakeSlice as CakeIcon } from 'react-icons/gi';
import styles from './FormSubmissionContent.module.css';
import formSentImage from '../assets/images/art/form-sent-image-1.svg'; // Update with your image path

const FormSubmissionContent = () => (
  <div className={styles.content}>
    <h2 className={styles.header}>On The Way!</h2>
    <p className={styles.text}>Have A Cake Break, We’ll Email You Soon.</p>
    <img src={formSentImage} alt="Form Sent" className={styles.image} />
    <div className={styles.logoContainer}>
      <CakeIcon size={50} className={styles.cakeIcon} />
      <span className={styles.companyName}>Simple <b>Software</b></span>
    </div>
  </div>
);

export default FormSubmissionContent;
