// images.js


const  companyTickerImagesDict = {
 1: {
   id: 1,
   src: require('./assets/animation_resources/company_ticker_animation_resources/company_ticker_images/hmi.png'),
   title: 'hmi',
   description: 'hmi logo black v1'
 },
 2: {
   id: 2,
   src: require('./assets/animation_resources/company_ticker_animation_resources/company_ticker_images/gro.png'),
   title: 'gro',
   description: 'gro logo black v1'
 },
 3: {
   id: 3,
   src: require('./assets/animation_resources/company_ticker_animation_resources/company_ticker_images/everysity.png'),
   title: 'everysity',
   description: 'everysity logo black v1'
 },
 4: {
   id: 4,
   src: require('./assets/animation_resources/company_ticker_animation_resources/company_ticker_images/purelift.png'),
   title: 'purelift',
   description: 'purelift logo black v1'
 },
 5: {
   id: 5,
   src: require('./assets/animation_resources/company_ticker_animation_resources/company_ticker_images/socristo.png'),
   title: 'socristo',
   description: 'socristo logo black v1'
 },
 6: {
   id: 6,
   src: require('./assets/animation_resources/company_ticker_animation_resources/company_ticker_images/therecoverylab.png'),
   title: 'therecoverylab',
   description: 'therecoverylab logo black v1'
 },
 7: {
   id: 7,
   src: require('./assets/animation_resources/company_ticker_animation_resources/company_ticker_images/xtreempulse.png'),
   title: 'xtreempulse',
   description: 'xtreempulse logo black v1'
 },
 8: {
   id: 8,
   src: require('./assets/animation_resources/company_ticker_animation_resources/company_ticker_images/ymca.png'),
   title: 'ymca',
   description: 'ymca logo black v1'
 },
 9: {
  id: 9,
  src: require('./assets/animation_resources/company_ticker_animation_resources/company_ticker_images/playerrecruit.png'),
  title: 'playerrecruit',
  description: 'player recruit logo black v1'
  },
  10: {
    id: 10,
    src: require('./assets/animation_resources/company_ticker_animation_resources/company_ticker_images/wrcnaples.png'),
    title: 'wrcnaples',
    description: 'wrcnaples logo black 4'
  },
  11: {
    id: 11,
    src: require('./assets/animation_resources/company_ticker_animation_resources/company_ticker_images/rogermoenks.png'),
    title: 'rogermoenks',
    description: 'rogermoenks'
  },
  12: {
    id: 12,
    src: require('./assets/animation_resources/company_ticker_animation_resources/company_ticker_images/smartcodes.png'),
    title: 'smartcodes',
    description: 'smartcodes'
  },
};

export default companyTickerImagesDict;